
import { computed, defineComponent } from 'vue'
import IncludeHtmlViewer from '@/components/templates/IncludeHtmlViewer.vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'StaticPage',
  components: {
    IncludeHtmlViewer,
  },
  props: {
    htmlName: {
      type: String,
      require: false,
    },
  },
  setup (props) {
    const route = useRoute()
    // アンカーリンクを子コンポーネントに渡すため
    const hash = route.hash

    return {
      html: computed(() => props.htmlName || (route.params.static + '.html')),
      hash,
    }
  },
})
